import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        tenantId: "8d894c2b-238f-490b-8dd1-d93898c5bf83",
        clientId: "8ab735c9-5e4c-463c-ab7c-f5130a45b86b",
        authority: "https://login.microsoftonline.com/8d894c2b-238f-490b-8dd1-d93898c5bf83/",
        //redirectUri: "https://1tyme.csio.unisys.com/",
        redirectUri: "https://1tyme.csio.unisys.com/auth.html",
        //redirectUri: "http://localhost:52530/auth.html",
        //redirectUri: "http://localhost:8080/auth.html",
        validateAuthority: true,
        //redirectUri: "http://localhost:3000",
        //redirectUri: "https://1tyme.csio.unisys.com",
        //redirectUri: "http://localhost:8080",
        postLogoutRedirectUri: "https://1tyme.csio.unisys.com"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    //scopes: ["https://UnisysCorp.onmicrosoft.com/1993a541-548b-4291-9a35-9d285cc82fab/user_impersonation"]
    //scopes: ["api://c9613a93-04a1-4dc0-b038-973dfeed313c/api.clientaccess"],
    //scopes: ["api://c9613a93-04a1-4dc0-b038-973dfeed313c/api.clientaccess","user.read", "openid", "profile"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/"
};
// export const appRoles = {
//     Admin: "Admin.User",
//     AppUser: "App.User"
//   };