import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./styles/theme";

import App from './App';

import { BrowserRouter as Router } from "react-router-dom";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
//console.log("Acccounts");
//console.log(accounts);
if (accounts.length == 1) {
  const account = accounts[0];
  //console.log(account?.tenantId)
  if (account?.tenantId === msalConfig.auth.tenantId) {
    msalInstance.setActiveAccount(account);
  }
} else if (accounts.length > 1) {
  accounts.forEach((account) => {
    if (account?.tenantId === msalConfig.auth.tenantId) {
      msalInstance.setActiveAccount(account);
    }
  });
}


// Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
// }

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// });

function loginRedirect() {
  try {
    const loginRequest = {
      scopes: ["User.Read"],
    };
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.log(err);
  }
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error?.name === "InteractionRequiredAuthError") {
      //loginRedirect();
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE");
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.name === "BrowserAuthError") {
      //loginRedirect();
    } else {
      console.log("LOGIN FAILURE");
    }
  } else {
    console.log("Callback finished");
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    //if (window.location.pathname.startsWith(protectedResources.quizApi.path)) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        //loginRedirect();
      }
    //}
  })
  .catch((err) => {
    console.log(err);
  });

console.log(msalInstance);
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <App pca={msalInstance} />
    </Provider>
);
reportWebVitals()
