import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom'

import { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import './scss/style.scss'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

const Login = React.lazy(() => import('./containers/Login/Login'))
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const NotFound = React.lazy(() => import('./containers/PageNotFound/404'))

function App({ pca }) {
  
  //const location = useLocation();
  const TRANSITION_DEFAULT = {
    classNames: 'fade',
    timeout: { enter: 250, exit: 250 }
  };

  //console.log("Is Authenticated");
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  //console.log(isAuthenticated);

  
  //const cssKey = location.pathname?.split('/')[1] || '/';
  //const curRoute = routes.find((x) => (x.path === cssKey) || (x.name.toLowerCase() === cssKey.toLowerCase()));
  //const { timeout, classNames } = curRoute?.transition ?? TRANSITION_DEFAULT;
 
  return (
    <BrowserRouter pca={pca}>
      <MsalProvider instance={pca}>
        <Suspense fallback={loading}>
        <SwitchTransition mode="out-in">
        <CSSTransition
          // key={cssKey}
           timeout={2}
          //classNames={classNames}
        >
          <Routes>
            <Route  path="/login" element={<Login pca={pca} />} />
            {/* <Route  path="/not-found" element={<NotFound />} /> */}
            <Route path="*"  element={<DefaultLayout />} />
          </Routes>
          </CSSTransition>
      </SwitchTransition>
        </Suspense>
        </MsalProvider>
        {/* <div className="App">
      <div>
        <button onClick={showToast}>Show Toast !</button>
        <ToastContainer />
      </div>
    </div> */}
    <ToastContainer  transition={Bounce} theme="colored"/>
      </BrowserRouter>
      
  );
}

function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

export default App;
